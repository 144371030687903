import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2d4e3ff0&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=2d4e3ff0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnMuted: require('/var/www/html/fantini/frontend-2/components/btn/muted/index.vue').default,Btn: require('/var/www/html/fantini/frontend-2/components/btn/index.vue').default,VectorIcon: require('/var/www/html/fantini/frontend-2/components/vector/icon/index.vue').default,Collapse: require('/var/www/html/fantini/frontend-2/components/collapse/index.vue').default,GridCss: require('/var/www/html/fantini/frontend-2/components/grid/css/index.vue').default,VectorLogoFantini: require('/var/www/html/fantini/frontend-2/components/vector/logo/fantini/index.vue').default,VectorIllustrationWorld: require('/var/www/html/fantini/frontend-2/components/vector/illustration/world/index.vue').default,FormLanguageMarket: require('/var/www/html/fantini/frontend-2/components/form/language-market/index.vue').default,Modal: require('/var/www/html/fantini/frontend-2/components/modal/index.vue').default})
