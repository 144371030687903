import { render, staticRenderFns } from "./index.vue?vue&type=template&id=11387170&"
import script from "./index.vue?vue&type=script&setup=true&lang=js&"
export * from "./index.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VectorIcon: require('/var/www/html/fantini/frontend-2/components/vector/icon/index.vue').default,Btn: require('/var/www/html/fantini/frontend-2/components/btn/index.vue').default,BtnNav: require('/var/www/html/fantini/frontend-2/components/btn/nav/index.vue').default,VectorLogoFantini: require('/var/www/html/fantini/frontend-2/components/vector/logo/fantini/index.vue').default,BtnCircle: require('/var/www/html/fantini/frontend-2/components/btn/circle/index.vue').default,ModalUser: require('/var/www/html/fantini/frontend-2/components/modal/user/index.vue').default,ToolbarNav: require('/var/www/html/fantini/frontend-2/components/toolbar/nav/index.vue').default,ToolbarFindProductsSearch: require('/var/www/html/fantini/frontend-2/components/toolbar/find-products-search/index.vue').default,TransitionSlide: require('/var/www/html/fantini/frontend-2/components/transition/slide/index.vue').default})
