import axios from "axios";
import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";
import { it, es, en, fr, de, ru, zh } from "@braid/vue-formulate-i18n";

export default async function ({
  store,
  route,
  $cookies,
  $config: { wsURL },
  req,
}) {
  if (
    route.params.locale &&
    /^it-it|it-ww|it-us|es-it|es-ww|es-us|en-it|en-ww|en-us|fr-it|fr-ww|fr-us|de-it|de-ww|de-us|ru-it|ru-ww|ru-us|zh-it|zh-ww|zh-us$/.test(
      route.params.locale
    )
  ) {
    Vue.use(VueFormulate, {
      plugins: [it, es, en, fr, de, ru, zh],
      locale: `${route.params.locale.split("-")[0]}`,
      validationNameStrategy: ["validationName", "label"],
    });
  }

  window.onNuxtReady(async () => {
    /* axios
      .get(
        `${wsURL}/wp-json/ta/v1/get?type=country-code-2&ip=${store.state.userIp}`
      )
      .then(({ data }) => {
        console.log("country code", data);
      }); */

    if (!store.state.fromName && !store.state.user.token) {
      const token = $cookies.get("ft-token");
      const id = $cookies.get("ft-id");

      axios
        .post(
          `${store.state.wsPath}get?type=users&action=validate`,
          JSON.stringify({
            token,
            id,
          })
        )
        .catch(function (error) {
          console.log(error);
        })
        .then(({ data }) => {
          if (data.code && data.code == "jwt_auth_valid_token") {
            store.commit("user/setUser", {
              ...data.user,
              id,
              token,
            });
          } else {
            store.commit("user/setUser", null);
          }
        });
    }

    if (route.path == "/404") {
      return;
    }

    function testLocale(locale) {
      const locales = [];
      for (let i = 0; i < store.state.markets.length; i++) {
        for (let j = 0; j < store.state.langs.length; j++) {
          locales.push(`${store.state.langs[j]}-${store.state.markets[i]}`);
        }
      }
      return new RegExp(`^${locales.join("$|^")}$`).test(locale);
    }

    function testLocaleString(locale) {
      const locales = [];
      const mm = store.state.markets.concat(["xx"]);
      const ll = store.state.langs.concat(["xx"]);
      for (let i = 0; i < mm.length; i++) {
        for (let j = 0; j < ll.length; j++) {
          locales.push(`${ll[j]}-${mm[i]}`);
        }
      }
      return new RegExp(`^${locales.join("$|^")}$`).test(locale);
    }

    function testLang(locale) {
      return new RegExp(`^${store.state.langs.join("$|^")}$`).test(locale);
    }

    function testMarket(market) {
      return new RegExp(`^${store.state.markets.join("$|^")}$`).test(market);
    }

    function initVueFormulateLabels() {
      Vue.use(VueFormulate, {
        plugins: [it, es, en, fr, de, ru, zh],
        locale: store.state.lang,
      });
    }

    function getUserLangCode() {
      //checks if user browsers defaults are inclueded in the available languages
      // (browsers can return both a single language or an array of languages)
      const languages = navigator.languages || navigator.language;
      if (typeof languages === "string" || languages instanceof String) {
        if (testLang(languages)) return languages;
      } else if (Array.isArray(languages)) {
        for (let i = 0, len = languages.length; i < len; i++) {
          const langCode = languages[i].split("-")[0];
          if (testLang(langCode)) {
            return langCode;
          }
        }
      }

      return "en";
    }

    function getUserMarketByCountry(country) {
      if (country == "IT") {
        return "it";
      } else if (/^US$|^CA$/.test(country)) {
        return "us";
      } else {
        return "ww";
      }
    }

    function saveLocale(lang, market) {
      store.commit("setLocale", {
        lang: lang,
        market: market,
      });

      $cookies.set("ft_lang", lang, {
        path: "/",
        expires: new Date("Fri, 31 Dec 9999 23:59:59"),
      });
      $cookies.set("ft_market", market, {
        path: "/",
        expires: new Date("Fri, 31 Dec 9999 23:59:59"),
      });
    }

    // LANG-MARKET

    const routeLocale = route.params.locale;

    let lang = testLang($cookies.get("ft_lang"))
      ? $cookies.get("ft_lang")
      : null;

    let market = testMarket($cookies.get("ft_market"))
      ? $cookies.get("ft_market")
      : null;

    const isValidLocale = testLocale(routeLocale);

    let uriLang;
    let uriMarket;

    if (testLocaleString(routeLocale)) {
      // check if a valid locale exists including xx-xx
      uriLang = routeLocale.split("-")[0].toLowerCase();
      uriMarket = routeLocale.split("-")[1].toLowerCase();
    }

    if (routeLocale) {
      if (isValidLocale) {
        // prioritize url over local storage
        lang = uriLang;
        market = uriMarket;
      } else if (testLang(routeLocale.split("-")[0].toLowerCase())) {
        lang = routeLocale.split("-")[0].toLowerCase();
      } else if (testMarket(routeLocale.split("-")[1].toLowerCase())) {
        market = routeLocale.split("-")[1].toLowerCase();
      }
    }

    if (testLang(lang) && testMarket(market)) {
      // saveLocale(lang, market);
      if (isValidLocale) {
        // Formulate and loading already initialized serverside
        return;
      } else {
        initVueFormulateLabels();
        store.dispatch("loadAll", { locale: lang }).then(() => {
          window.$nuxt.$router.push(getUri(`/${lang}-${market}`));
        });
      }
    } else {
      axios
        .get(`${wsURL}/wp-json/ta/v1/get?type=country-code`)
        .then(({ data }) => {
          saveLocale(getUserLangCode(), getUserMarketByCountry(data.country));
          initVueFormulateLabels();

          store.dispatch("loadAll", { locale: store.state.lang }).then(() => {
            window.$nuxt.$router.push(
              getUri(
                route.fullPath && route.params.locale
                  ? route.fullPath
                  : `/${store.state.lang}-${store.state.market}`
              )
            );
          });
        });
    }
  });
  function getUri(path) {
    function appendQueryParams(path, exclude) {
      let query = [];
      for (let p in route.query) {
        if (p != exclude) {
          query.push(`${p}=${route.query[p]}`);
        }
      }
      path = `${path}${query.length ? "?" + query.join("&") : ""}`;
      return path;
    }
    if (route.name == "index") {
      if (route.query["xx-xx"]) {
        let query = [];
        for (let p in route.query) {
          if (p != "xx-xx") {
            query.push(`${p}=${route.query[p]}`);
          }
        }
        return appendQueryParams(`${path}/${route.query["xx-xx"]}`, "xx-xx");
      } else {
        let url;

        for (let p in route.query) {
          if (new RegExp(`^(${store.state.langs.join("|")})-xx$`).test(p)) {
            url = `${p.replace("xx", path.split("-")[1])}/${route.query[p]}`;
            return appendQueryParams(`${url}`, p);
          } else if (
            new RegExp(`^xx-(${store.state.markets.join("|")})$`).test(p)
          ) {
            url = `${p.replace("xx", path.split("-")[0])}/${route.query[p]}`;
            return appendQueryParams(`${url}`, p);
          }
        }
      }
    }

    return path;
  }
}
