import redirects from "../assets/js/redirects.js";
import parser from "accept-language-parser";

import find from "lodash/find";
export default async function ({
  route,
  from,
  store,
  redirect,
  $cookies,
  req,
}) {

  function testLocale(locale) {
    const locales = [];
    for (let i = 0; i < store.state.markets.length; i++) {
      for (let j = 0; j < store.state.langs.length; j++) {
        locales.push(`${store.state.langs[j]}-${store.state.markets[i]}`);
      }
    }
    return new RegExp(`^${locales.join("$|^")}$`).test(locale);
  }

  // if (process.server) {
  //   store.commit(
  //     "setIp",
  //     req.connection.remoteAddress || req.socket.remoteAddress
  //   );
  // }

  function testLang(lang) {
    const locales = [];
    return new RegExp(`^${store.state.langs.join("$|^")}$`).test(lang);
  }

  function parseLanguages(request) {
    const headers = req && req.headers ? Object.assign({}, req.headers) : {};
    const languageHeader = headers["accept-language"];
    const languages = parser.parse(languageHeader);
    return languages;
  }

  const detectedLanguages = parseLanguages(req);
  let detectedLanguage = "en";
  for (let i = 0; i < detectedLanguages.length; i++) {
    if (testLang(detectedLanguages[i].code)) {
      detectedLanguage = detectedLanguages[i].code;
      break;
    }
  }

  store.commit("setReqLang", detectedLanguage);

  if (route.path == "/auth") {
    return;
  }

  if (route.path == "/404") {
    return;
  }

  if (!route.params.locale) {
    const cookiesLocale = `${$cookies.get("ft_lang")}-${$cookies.get(
      "ft_market"
    )}`;
    if (testLocale(cookiesLocale)) {
      await store.dispatch("loadAll", { locale: $cookies.get("ft_lang") });
      console.log("cookiesLocale redirect ---****->", cookiesLocale);

      return redirect(`${getUri(cookiesLocale)}`);
    }
  }

  const redirectRule = find(redirects, (el) => {
    return new RegExp(el.from.replace(/\?/g, "\\?")).test(route.fullPath);
  });

  if (redirectRule) {
    redirect(redirectRule.to);
    return;
  }

  if (/\/collection\/fantini|\/collection\/accessori/.test(route.path)) {
    return redirect("/");
  }

  if (route.path.match("/legal-info/terms-and-conditions")) {
    return redirect(route.path.replace("terms-and-conditions", "legal-notes"));
  }

  if (route.params.locale && !testLocale(route.params.locale)) {
    return redirect("/");
  }

  if (process.client) {
    store.commit("setFromRoute", {
      from: from,
      fromName: from ? from.name : "",
      toName: route.name,
    });
  }

  function getUri(path) {
    function appendQueryParams(path, exclude) {
      let query = [];
      for (let p in route.query) {
        if (p != exclude) {
          query.push(`${p}=${route.query[p]}`);
        }
      }
      path = `${path}${query.length ? "?" + query.join("&") : ""}`;
      return path;
    }

    if (route.name == "index") {
      if (route.query["xx-xx"]) {
        let query = [];
        for (let p in route.query) {
          if (p != "xx-xx") {
            query.push(`${p}=${route.query[p]}`);
          }
        }
        return appendQueryParams(`${path}/${route.query["xx-xx"]}`, "xx-xx");
      } else {
        let url;

        for (let p in route.query) {
          if (new RegExp(`^(${store.state.langs.join("|")})-xx$`).test(p)) {
            url = `${p.replace("xx", path.split("-")[1])}/${route.query[p]}`;
            return appendQueryParams(`${url}`, p);
          } else if (
            new RegExp(`^xx-(${store.state.markets.join("|")})$`).test(p)
          ) {
            url = `${p.replace("xx", path.split("-")[0])}/${route.query[p]}`;
            return appendQueryParams(`${url}`, p);
          }
        }
      }
    }

    return path;
  }
}
