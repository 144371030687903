import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2db59eac = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _04694d7e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5a2f8668 = () => interopDefault(import('../pages/_locale/index.vue' /* webpackChunkName: "pages/_locale/index" */))
const _0bdec0da = () => interopDefault(import('../pages/_locale/account-activation/index.vue' /* webpackChunkName: "pages/_locale/account-activation/index" */))
const _7833b78e = () => interopDefault(import('../pages/_locale/careers/index.vue' /* webpackChunkName: "pages/_locale/careers/index" */))
const _79a37b39 = () => interopDefault(import('../pages/_locale/confirm-account/index.vue' /* webpackChunkName: "pages/_locale/confirm-account/index" */))
const _dedc2afe = () => interopDefault(import('../pages/_locale/designers/index.vue' /* webpackChunkName: "pages/_locale/designers/index" */))
const _6a9efe4e = () => interopDefault(import('../pages/_locale/discover-collections/index.vue' /* webpackChunkName: "pages/_locale/discover-collections/index" */))
const _69bd026a = () => interopDefault(import('../pages/_locale/download-area/index.vue' /* webpackChunkName: "pages/_locale/download-area/index" */))
const _57b240de = () => interopDefault(import('../pages/_locale/find-products/index.vue' /* webpackChunkName: "pages/_locale/find-products/index" */))
const _eaac9e20 = () => interopDefault(import('../pages/_locale/news/index.vue' /* webpackChunkName: "pages/_locale/news/index" */))
const _ed34fe5e = () => interopDefault(import('../pages/_locale/preview-test.vue' /* webpackChunkName: "pages/_locale/preview-test" */))
const _37aa96c5 = () => interopDefault(import('../pages/_locale/professionals/index.vue' /* webpackChunkName: "pages/_locale/professionals/index" */))
const _1bbc68d7 = () => interopDefault(import('../pages/_locale/projects/index.vue' /* webpackChunkName: "pages/_locale/projects/index" */))
const _5f64e687 = () => interopDefault(import('../pages/_locale/reset-password-link/index.vue' /* webpackChunkName: "pages/_locale/reset-password-link/index" */))
const _3c811d05 = () => interopDefault(import('../pages/_locale/search/index.vue' /* webpackChunkName: "pages/_locale/search/index" */))
const _66b89c75 = () => interopDefault(import('../pages/_locale/signup/index.vue' /* webpackChunkName: "pages/_locale/signup/index" */))
const _4fb8700c = () => interopDefault(import('../pages/_locale/account/lists/index.vue' /* webpackChunkName: "pages/_locale/account/lists/index" */))
const _e57cd3c0 = () => interopDefault(import('../pages/_locale/account/profile/index.vue' /* webpackChunkName: "pages/_locale/account/profile/index" */))
const _4ea8c789 = () => interopDefault(import('../pages/_locale/company/csr/index.vue' /* webpackChunkName: "pages/_locale/company/csr/index" */))
const _b39d2a3a = () => interopDefault(import('../pages/_locale/company/dna-design/index.vue' /* webpackChunkName: "pages/_locale/company/dna-design/index" */))
const _68094be8 = () => interopDefault(import('../pages/_locale/company/overview/index.vue' /* webpackChunkName: "pages/_locale/company/overview/index" */))
const _1088d846 = () => interopDefault(import('../pages/_locale/company/quality/index.vue' /* webpackChunkName: "pages/_locale/company/quality/index" */))
const _8c6954be = () => interopDefault(import('../pages/_locale/company/waterfront/index.vue' /* webpackChunkName: "pages/_locale/company/waterfront/index" */))
const _083207b0 = () => interopDefault(import('../pages/_locale/contacts/customer-care-and-spare-parts/index.vue' /* webpackChunkName: "pages/_locale/contacts/customer-care-and-spare-parts/index" */))
const _116e2d1b = () => interopDefault(import('../pages/_locale/contacts/customer-service/index.vue' /* webpackChunkName: "pages/_locale/contacts/customer-service/index" */))
const _14a8bdd8 = () => interopDefault(import('../pages/_locale/contacts/headquarters-and-showrooms/index.vue' /* webpackChunkName: "pages/_locale/contacts/headquarters-and-showrooms/index" */))
const _51fbae06 = () => interopDefault(import('../pages/_locale/contacts/professional-consulting/index.vue' /* webpackChunkName: "pages/_locale/contacts/professional-consulting/index" */))
const _43678199 = () => interopDefault(import('../pages/_locale/contacts/sales-points/index.vue' /* webpackChunkName: "pages/_locale/contacts/sales-points/index" */))
const _065f7fea = () => interopDefault(import('../pages/_locale/legal-info/cookie-policy/index.vue' /* webpackChunkName: "pages/_locale/legal-info/cookie-policy/index" */))
const _7d8abcbd = () => interopDefault(import('../pages/_locale/legal-info/legal-notes/index.vue' /* webpackChunkName: "pages/_locale/legal-info/legal-notes/index" */))
const _1de05e1d = () => interopDefault(import('../pages/_locale/legal-info/privacy-policy/index.vue' /* webpackChunkName: "pages/_locale/legal-info/privacy-policy/index" */))
const _1dcbe823 = () => interopDefault(import('../pages/_locale/press/kit/index.vue' /* webpackChunkName: "pages/_locale/press/kit/index" */))
const _141c7f9c = () => interopDefault(import('../pages/_locale/press/request/index.vue' /* webpackChunkName: "pages/_locale/press/request/index" */))
const _466834fe = () => interopDefault(import('../pages/_locale/press/review/index.vue' /* webpackChunkName: "pages/_locale/press/review/index" */))
const _7ecffc02 = () => interopDefault(import('../pages/_locale/account/profile/password-reset/index.vue' /* webpackChunkName: "pages/_locale/account/profile/password-reset/index" */))
const _64119568 = () => interopDefault(import('../pages/_locale/account/profile/password-update/index.vue' /* webpackChunkName: "pages/_locale/account/profile/password-update/index" */))
const _69075209 = () => interopDefault(import('../pages/_locale/company/waterfront/la-fabbrica-sul-lago/index.vue' /* webpackChunkName: "pages/_locale/company/waterfront/la-fabbrica-sul-lago/index" */))
const _2be6a41d = () => interopDefault(import('../pages/_locale/company/waterfront/lake-time/index.vue' /* webpackChunkName: "pages/_locale/company/waterfront/lake-time/index" */))
const _d401c684 = () => interopDefault(import('../pages/_locale/company/csr/projects/_id.vue' /* webpackChunkName: "pages/_locale/company/csr/projects/_id" */))
const _134f0582 = () => interopDefault(import('../pages/_locale/news/article/_id.vue' /* webpackChunkName: "pages/_locale/news/article/_id" */))
const _5e2942ba = () => interopDefault(import('../pages/_locale/collection/_id.vue' /* webpackChunkName: "pages/_locale/collection/_id" */))
const _1e2acdae = () => interopDefault(import('../pages/_locale/designers/_id.vue' /* webpackChunkName: "pages/_locale/designers/_id" */))
const _6ce527d6 = () => interopDefault(import('../pages/_locale/lists/_id.vue' /* webpackChunkName: "pages/_locale/lists/_id" */))
const _a0a760a0 = () => interopDefault(import('../pages/_locale/product/_id.vue' /* webpackChunkName: "pages/_locale/product/_id" */))
const _442cb4ff = () => interopDefault(import('../pages/_locale/projects/_id.vue' /* webpackChunkName: "pages/_locale/projects/_id" */))
const _baa91846 = () => interopDefault(import('../pages/_locale/showroom/_id.vue' /* webpackChunkName: "pages/_locale/showroom/_id" */))
const _84166ace = () => interopDefault(import('../pages/_locale/serie/_id/concept/index.vue' /* webpackChunkName: "pages/_locale/serie/_id/concept/index" */))
const _5afb23dc = () => interopDefault(import('../pages/_locale/serie/_id/downloads/index.vue' /* webpackChunkName: "pages/_locale/serie/_id/downloads/index" */))
const _40dba9fa = () => interopDefault(import('../pages/_locale/serie/_id/gallery/index.vue' /* webpackChunkName: "pages/_locale/serie/_id/gallery/index" */))
const _36de6329 = () => interopDefault(import('../pages/_locale/serie/_id/products/index.vue' /* webpackChunkName: "pages/_locale/serie/_id/products/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _2db59eac,
    name: "auth"
  }, {
    path: "/",
    component: _04694d7e,
    name: "index"
  }, {
    path: "/:locale",
    component: _5a2f8668,
    name: "locale"
  }, {
    path: "/:locale/account-activation",
    component: _0bdec0da,
    name: "locale-account-activation"
  }, {
    path: "/:locale/careers",
    component: _7833b78e,
    name: "locale-careers"
  }, {
    path: "/:locale/confirm-account",
    component: _79a37b39,
    name: "locale-confirm-account"
  }, {
    path: "/:locale/designers",
    component: _dedc2afe,
    name: "locale-designers"
  }, {
    path: "/:locale/discover-collections",
    component: _6a9efe4e,
    name: "locale-discover-collections"
  }, {
    path: "/:locale/download-area",
    component: _69bd026a,
    name: "locale-download-area"
  }, {
    path: "/:locale/find-products",
    component: _57b240de,
    name: "locale-find-products"
  }, {
    path: "/:locale/news",
    component: _eaac9e20,
    name: "locale-news"
  }, {
    path: "/:locale/preview-test",
    component: _ed34fe5e,
    name: "locale-preview-test"
  }, {
    path: "/:locale/professionals",
    component: _37aa96c5,
    name: "locale-professionals"
  }, {
    path: "/:locale/projects",
    component: _1bbc68d7,
    name: "locale-projects"
  }, {
    path: "/:locale/reset-password-link",
    component: _5f64e687,
    name: "locale-reset-password-link"
  }, {
    path: "/:locale/search",
    component: _3c811d05,
    name: "locale-search"
  }, {
    path: "/:locale/signup",
    component: _66b89c75,
    name: "locale-signup"
  }, {
    path: "/:locale/account/lists",
    component: _4fb8700c,
    name: "locale-account-lists"
  }, {
    path: "/:locale/account/profile",
    component: _e57cd3c0,
    name: "locale-account-profile"
  }, {
    path: "/:locale/company/csr",
    component: _4ea8c789,
    name: "locale-company-csr"
  }, {
    path: "/:locale/company/dna-design",
    component: _b39d2a3a,
    name: "locale-company-dna-design"
  }, {
    path: "/:locale/company/overview",
    component: _68094be8,
    name: "locale-company-overview"
  }, {
    path: "/:locale/company/quality",
    component: _1088d846,
    name: "locale-company-quality"
  }, {
    path: "/:locale/company/waterfront",
    component: _8c6954be,
    name: "locale-company-waterfront"
  }, {
    path: "/:locale/contacts/customer-care-and-spare-parts",
    component: _083207b0,
    name: "locale-contacts-customer-care-and-spare-parts"
  }, {
    path: "/:locale/contacts/customer-service",
    component: _116e2d1b,
    name: "locale-contacts-customer-service"
  }, {
    path: "/:locale/contacts/headquarters-and-showrooms",
    component: _14a8bdd8,
    name: "locale-contacts-headquarters-and-showrooms"
  }, {
    path: "/:locale/contacts/professional-consulting",
    component: _51fbae06,
    name: "locale-contacts-professional-consulting"
  }, {
    path: "/:locale/contacts/sales-points",
    component: _43678199,
    name: "locale-contacts-sales-points"
  }, {
    path: "/:locale/legal-info/cookie-policy",
    component: _065f7fea,
    name: "locale-legal-info-cookie-policy"
  }, {
    path: "/:locale/legal-info/legal-notes",
    component: _7d8abcbd,
    name: "locale-legal-info-legal-notes"
  }, {
    path: "/:locale/legal-info/privacy-policy",
    component: _1de05e1d,
    name: "locale-legal-info-privacy-policy"
  }, {
    path: "/:locale/press/kit",
    component: _1dcbe823,
    name: "locale-press-kit"
  }, {
    path: "/:locale/press/request",
    component: _141c7f9c,
    name: "locale-press-request"
  }, {
    path: "/:locale/press/review",
    component: _466834fe,
    name: "locale-press-review"
  }, {
    path: "/:locale/account/profile/password-reset",
    component: _7ecffc02,
    name: "locale-account-profile-password-reset"
  }, {
    path: "/:locale/account/profile/password-update",
    component: _64119568,
    name: "locale-account-profile-password-update"
  }, {
    path: "/:locale/company/waterfront/la-fabbrica-sul-lago",
    component: _69075209,
    name: "locale-company-waterfront-la-fabbrica-sul-lago"
  }, {
    path: "/:locale/company/waterfront/lake-time",
    component: _2be6a41d,
    name: "locale-company-waterfront-lake-time"
  }, {
    path: "/:locale/company/csr/projects/:id?",
    component: _d401c684,
    name: "locale-company-csr-projects-id"
  }, {
    path: "/:locale/news/article/:id?",
    component: _134f0582,
    name: "locale-news-article-id"
  }, {
    path: "/:locale/collection/:id?",
    component: _5e2942ba,
    name: "locale-collection-id"
  }, {
    path: "/:locale/designers/:id",
    component: _1e2acdae,
    name: "locale-designers-id"
  }, {
    path: "/:locale/lists/:id?",
    component: _6ce527d6,
    name: "locale-lists-id"
  }, {
    path: "/:locale/product/:id?",
    component: _a0a760a0,
    name: "locale-product-id"
  }, {
    path: "/:locale/projects/:id",
    component: _442cb4ff,
    name: "locale-projects-id"
  }, {
    path: "/:locale/showroom/:id?",
    component: _baa91846,
    name: "locale-showroom-id"
  }, {
    path: "/:locale/serie/:id?/concept",
    component: _84166ace,
    name: "locale-serie-id-concept"
  }, {
    path: "/:locale/serie/:id?/downloads",
    component: _5afb23dc,
    name: "locale-serie-id-downloads"
  }, {
    path: "/:locale/serie/:id?/gallery",
    component: _40dba9fa,
    name: "locale-serie-id-gallery"
  }, {
    path: "/:locale/serie/:id?/products",
    component: _36de6329,
    name: "locale-serie-id-products"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
