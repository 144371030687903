import omit from "lodash/omit";
import axios from "axios";

export const state = () => ({
  langs: ["it", "es", "en", "fr", "de", "ru", "zh"],
  markets: ["it", "ww", "us"],
  host: "",
  wsPath: "",
  wpPath: "",
  market: "",
  lang: "",
  locale: "",
  fromName: "",
  toName: "",
  router: {
    count: 0,
    from: {},
  },
  staging: false,
  iOs: null,
  reqLang: "it",
  cloudinaryPath: null,
  cloudinaryPathPdf: null,
  cloudinaryPathZip: null,
  cloudinaryFolder: null,
  useSDM: false,
  userIp: null,
  firstAccessRoute: null,
});

export const mutations = {
  setWpURL(state, path) {
    state.wpPath = `${path}/wp-json/wp/v2`;
  },
  setWsURL(state, path) {
    state.wsPath = `${path}/wp-json/ta/v1/`;
  },
  setIOS(state, value) {
    state.iOs = value;
  },
  setCloudinary(state, data) {
    state.supportsAvif = data.avif;
    state.cloudinaryFolder = data.folder;
    state.cloudinaryPath = `https://res.cloudinary.com/thinking/image/upload/${
      data.avif ? "f_avif" : "f_auto"
    }/q_auto:eco/${data.folder}/`;
    state.cloudinaryPathPdf = `https://res.cloudinary.com/thinking/image/upload/${data.folder}/`;
    state.cloudinaryPathZip = `https://res.cloudinary.com/thinking/raw/upload/${data.folder}/`;
  },
  setUseSDM(state, data) {
    state.useSDM = data;
  },
  setFromRoute(state, obj) {
    state.fromName = obj.fromName;
    state.toName = obj.toName;
    state.router.from = omit(obj.from, ["matched", "meta"]);
    state.router.count++;
  },
  setLocale(state, data) {
    state.market = data.market;
    state.lang = data.lang;
    state.locale = `${data.lang}-${data.market}`;
  },
  setStaging(state, value) {
    state.staging = value;
  },
  setReqLang(state, value) {
    state.reqLang = value;
  },
  setIp(state, value) {
    state.userIp = value;
  },
  setHost(state, value) {
    state.host = value;
  },
};

export const actions = {
  async loadAll({ dispatch }, { locale, market }) {
    return await dispatch("labels/load", {
      lang: locale,
      market: market,
    });
  },
  async nuxtServerInit(
    { dispatch, commit },
    { params, payload, $config, req }
  ) {
    // get url
    commit("setIOS", /iPhone|iPad|iPod/.test(req.headers["user-agent"]));
    commit("setHost", req.headers["host"]);
    commit("setCloudinary", {
      avif: /image\/avif/.test(req.headers["accept"]),
      folder: $config.cloudinaryFolder,
    });
    commit("setUseSDM", $config.useSDM);

    const locale = params.locale?.split("-")[0];
    const market = params.locale?.split("-")[1];

    commit("setWsURL", $config.wsURL);
    commit("setWpURL", $config.wpURL);
    if (!locale) {
      return;
    }
    return await dispatch("loadAll", {
      payload: payload,
      locale: locale,
      market: market,
    });
  },
  async stagingLogin({ commit, state }, { username, password, recaptcha }) {
    if (!username || !password) {
      return { error: "Username or password missing" };
    }
    const { data } = await axios.post(
      `${state.wsPath}get?type=auth&user=${username}&pwd=${password}&recaptcha=${recaptcha}`
    );
    if (data.response == "success") {
      this.$cookies.set("ft_staging_oauth", 1, {
        path: "/",
        maxAge: 60 * 60 * 24 * 7 * 4, // 4 week
      });
    }
    return data;
  },
};
