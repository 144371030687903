import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0ca4e2aa&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormFindProductSearch: require('/var/www/html/fantini/frontend-2/components/form/find-product/search/index.vue').default})
