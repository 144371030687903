import axios from "axios";
import map from "lodash/map";
import find from "lodash/find";

export default async ({ app, route, from, store, redirect, req, $cookies }) => {
  console.log("auth ---****->", route.path, process.client);

  const host = req ? req.headers.host : window.location.host.split(":")[0];
  const isStaging =
    /fantini-v2.flowing.it/.test(host) ||
    /staging.fantini.it/.test(host) ||
    /ta.fantini.it/.test(host);
  store.commit("setStaging", isStaging);

  if (route.fullPath == "/auth") {
    if (!store.state.fromName && !isStaging) {
      return redirect("/");
    }
    if (!store.state.fromName && $cookies.get("ft_staging_oauth")) {
      return redirect("/");
    }
    return;
  } else {
    if (isStaging) {
      if (!$cookies.get("ft_staging_oauth")) {
        return redirect("/auth");
      }
    }
  }

  const AUTH_ROUTES = [
    { name: "locale-account-lists", redirect: "signup" },
    { name: "locale-account-profile", redirect: "signup" },
    { name: "locale-lists-id" },
    {
      name: "locale-account-profile-password-update",
      redirect: "signup",
    },
  ];

  function getRedirectURL() {
    if (!route.name) {
      return "";
    }

    let url =
      find(AUTH_ROUTES, (el) => new RegExp(`^${route.name}$`).test(el.name))
        ?.redirect || "";

    return url ? `/${route.params.locale}/${url}` : "";
  }

  // if (
  //   store.state.fromName &&
  //   !new RegExp(`${map(AUTH_ROUTES, "name").join("|")}`).test(route.name)
  // ) {
  //   return;
  // }

  const token = app.$cookies.get("ft-token");
  const id = app.$cookies.get("ft-id");

  if (
    route.query.preview ||
    new RegExp(`${map(AUTH_ROUTES, "name").join("|")}`).test(route.name)
  ) {
    const redirectURL = getRedirectURL();

    if (redirectURL && (!token || !id)) {
      redirect(redirectURL);
    }

    const { data } = await axios
      .post(
        `${store.state.wsPath}get?type=users&action=validate`,
        JSON.stringify({
          token,
          id,
        })
      )
      .catch(function (error) {
        if (redirectURL) {
          redirect(redirectURL);
        }
      });
    saveUser(data);
  } else {
    return;
  }

  function saveUser(data) {
    if (data.code && data.code == "jwt_auth_valid_token") {
      store.commit("user/setUser", {
        ...data.user,
        id,
        token,
      });
    } else {
      store.commit("user/setUser", null);
      redirect(getRedirectURL());
    }
  }
};
