import { render, staticRenderFns } from "./index.vue?vue&type=template&id=49266dfd&"
import script from "./index.vue?vue&type=script&setup=true&lang=js&"
export * from "./index.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputText: require('/var/www/html/fantini/frontend-2/components/input/text/index.vue').default,BtnPill: require('/var/www/html/fantini/frontend-2/components/btn/pill/index.vue').default,BtnGhost: require('/var/www/html/fantini/frontend-2/components/btn/ghost/index.vue').default,FormErrors: require('/var/www/html/fantini/frontend-2/components/form/errors/index.vue').default})
