import axios from "axios";
import useTailwindCss from "/composables/useTailwindCss";
import { ref, watch } from "vue";
import { useStore } from "@nuxtjs/composition-api";
import { templateRef, onClickOutside } from "@vueuse/core";
import { useMediaQuery } from "@vueuse/core";
import { useHeaderToolbar } from "@/composables/useHeaderToolbar";


export default {
  __name: 'index',
  setup(__props) {

const { screens } = useTailwindCss();
const store = useStore();
const isLGScreen = useMediaQuery(`(min-width: ${screens.lg.min})`);
const tpl_root = templateRef("tpl_root");
const vModel = ref({ keyword: "" });
const results = ref(null);
const inputFocused = ref(false);
const loading = ref(false);
const { toolbar } = useHeaderToolbar();

const CancelToken = axios.CancelToken;
let source;

onClickOutside(tpl_root, (event) => {
  if (source) {
    source.cancel("Operation canceled by the user.");
  }
  loading.value = false;
  results.value = null;
});

watch(inputFocused, (newVal, oldVal) => {
  if (newVal && vModel.value.keyword.length >= 2) {
    handleSubmit();
  }
});

function handleSubmit() {
  if (source) {
    source.cancel("Operation canceled by the user.");
  }

  let key = vModel.value.keyword.replace(/(Art. |Art )/, "");

  if (key === "") {
    results.value = null;
    loading.value = false;
  } else if (key.length > 2) {
    results.value = null;
    // cancel the request
    loading.value = true;
    source = CancelToken.source();

    let ws;

    if (toolbar.value === "download-area_out-of-production") {
      ws = "search-oop";
    } else {
      ws = "search-product";
    }

    axios
      .get(
        //`${store.state.wsPath}get?type=search-products&term=${key}&lang=${store.state.lang}&market=${store.state.market}`,
        `${store.state.wsPath}${ws}?term=${key}&lang=${store.state.lang}&market=${store.state.market}`,
        {
          cancelToken: source.token,
        }
      )
      .then(({ data }) => {
        loading.value = false;
        results.value = data.items?.length ? data.items : [];
      })
      .catch((error) => {});
  } else {
    results.value = null;
    loading.value = false;
  }
}

return { __sfc: true,screens, store, isLGScreen, tpl_root, vModel, results, inputFocused, loading, toolbar, CancelToken, source, handleSubmit }
}

}