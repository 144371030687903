import { useStore, useRoute, useContext } from "@nuxtjs/composition-api";

export const useLocale = () => {
  const store = useStore();
  const route = useRoute();
  const { $cookies } = useContext();

  function testLocale(locale) {
    const locales = [];
    for (let i = 0; i < store.state.markets.length; i++) {
      for (let j = 0; j < store.state.langs.length; j++) {
        locales.push(`${store.state.langs[j]}-${store.state.markets[i]}`);
      }
    }
    return new RegExp(`^${locales.join("$|^")}$`).test(locale);
  }

  function changeLocale(lang, market) {
    if (!testLocale(`${lang}-${market}`)) {
      console.warn("Locale is not valid.");
      return;
    }

    // set cookie
    $cookies.set("ft_lang", lang, {
      path: "/",
      expires: new Date("Fri, 31 Dec 9999 23:59:59"),
    });
    $cookies.set("ft_market", market, {
      path: "/",
      expires: new Date("Fri, 31 Dec 9999 23:59:59"),
    });

    window.location.href = route.value.fullPath.replace(
      route.value.params.locale,
      `${lang}-${market}`
    );
  }

  return {
    testLocale,
    changeLocale,
  };
};
