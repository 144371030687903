import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7548df2e&scoped=true&"
import script from "./index.vue?vue&type=script&setup=true&lang=js&"
export * from "./index.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=7548df2e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7548df2e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnMuted: require('/var/www/html/fantini/frontend-2/components/btn/muted/index.vue').default,FormLogout: require('/var/www/html/fantini/frontend-2/components/form/logout/index.vue').default,FormSignin: require('/var/www/html/fantini/frontend-2/components/form/signin/index.vue').default,BtnPill: require('/var/www/html/fantini/frontend-2/components/btn/pill/index.vue').default,TransitionFade: require('/var/www/html/fantini/frontend-2/components/transition/fade/index.vue').default,Modal: require('/var/www/html/fantini/frontend-2/components/modal/index.vue').default})
